import React from 'react'
import styled from 'styled-components'
import {
    space,
    color,
    typography,
    border,
    layout,
    flexbox,
} from 'styled-system'

export const Box = styled.div`
    box-sizing: border-box;
    ${space}
    ${color}
    ${typography}
    ${border}
    ${layout}
    ${flexbox}
`

const HeadingBase = ({ level, as: Component = `h${level}`, ...props }) => (
    <Component {...props} />
)

export const Heading = styled(HeadingBase)`
    ${space}
    ${typography}
`

export const Text = styled.p`
    margin: 0;
    ${color}
    ${typography}
	${space}
`

export const Button = styled('button')`
    transition: 300ms;
    border-radius: 3px;
    backface-visibility: hidden;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.428571429;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    ${color}
    ${space}
    ${typography}
`

export const Flex = styled(Box)`
    display: flex;
`

export const Hr = styled.hr`
    ${space}
`

export const Link = styled.a`
    ${typography}
    ${space}
`
